*{
  scroll-behavior: smooth;
}


@font-face {
    font-family: acid;
    src: url('./acid.otf');
}
@font-face {
    font-family: desiras;
    src: url('./DesirasNonCommercial.otf');
}

$primary-color: #fff;
$secondary-color: #231f20;
$accent-color: #a6a9ab;
$fourth-color: #404040;

::-webkit-scrollbar {
    width: 12px;
  }

::-webkit-scrollbar-thumb {
    background: $secondary-color;
}
  
main {
    min-height: 60vh;
}
.text-primary-color{
  color: $primary-color;
}

.border-primary-color{
  border: 1px solid $primary-color;
}

.text-secondary-color{
  color: $secondary-color !important;
}

.text-accent-color{
  color: $accent-color;
}

.bgc-primary{
  background-color: $primary-color;
}

.bgc-secondary{
  background-color: $secondary-color !important;
}

.bgc-accent{
  background-color: $accent-color;
}

.bgc-footer{
    background-color: #000000;
}
// .form-control{
//   color:white;
// }

* {
  font-family: 'acid';
  font-size: 18px;
}

h1, h2, h3, h4 {
  font-family: 'desiras';
}

h1 {
    letter-spacing: 10px;
}

@media screen and (max-width: 350px) {

    h1 {
        letter-spacing: 3px;
    }

}

// h1, h2, h3 ,h4 , h5, .h1 , .h2, .h3 ,.h4 , .h5 {
//   font-family: 'Montserrat', sans-serif;
// }

body{
    background-color: whitesmoke;
}



div.masthead{
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 500px;
  width: 100%;
  overflow: hidden;
}

#navbar {
  transition: 0.5s;
}
.bg-navbar {
  background-color: rgba($color: #565656, $alpha: 0.6);
  backdrop-filter: blur(12px);

}

  header video {
    position: absolute;
    // top: 50%;
    // left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    // height: ;
    z-index: 0;
    // -ms-transform: translateX(-50%) translateY(-50%);
    // -moz-transform: translateX(-50%) translateY(-50%);
    // -webkit-transform: translateX(-50%) translateY(-50%);
    // transform: translateX(-50%) translateY(-50%);
  }

  header .container {
    position: relative;
    z-index: 2;
  }

  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  // @media (pointer: coarse) and (hover: none) {
  //   header {
  //     background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
  //   }
  //   header video {
  //     display: none;
  //   }
  // }


  .main-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba($color: $secondary-color, $alpha: 0.5);
    backdrop-filter: blur(8px);
    opacity: 0;
    z-index: 100;
    visibility: hidden;
    transition: all .375s;
      & .facebook {
        display: inline;
        padding-right: 0.75rem;
      }
      & .instagram {
        display: inline;
        padding-right: 0.75rem;

      }
      & .linkedin {
        display: inline;
        padding-right: 0.75rem;
      }
    }

    .main-nav.is-open {
        opacity: 1;
        z-index: 100;
        visibility: visible;
    }

    /* Yellow band effect */
    .main-nav::before {
        content: '';
        position: fixed;
        top: 66px;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        transform-origin: 0 0;
        transform: skew(-8deg) translateX(-100%);
        transition: all .275s .1s;
    }

    .main-nav.is-open::before {
        transform: skew(-8deg) translateX(0);
    }

    /* Skewing effect on menu links */
    .main-nav ul {
        display: inline-flex;
        flex-direction: column;
        height: 100%; /* Should be 100%, but we have a notice message :D */
        align-items: flex-end;
        justify-content: center;
        transform: translateX(-18%) skew(-14deg);
    }

    .main-nav li {
        display: block;
        margin: .5rem 0;
        text-align: right;
        transform: skew(14deg);
    }

    /* Apparition effect on links */
    .main-nav a {
        opacity: 0;
        transform: translateY(-10px);
    }

    .main-nav.is-open a {
        opacity: 1;
        transform: translateY(0);
    }
    .main-nav li:nth-child(1) a {
        transition: all 275ms 175ms
    }
    .main-nav li:nth-child(2) a {
        transition: all 275ms 250ms
    }
    .main-nav li:nth-child(3) a {
        transition: all 275ms 325ms
    }
    .main-nav li:nth-child(4) a {
        transition: all 275ms 400ms
    }
    .main-nav li:nth-child(5) a {
        transition: all 275ms 475ms
    }
    .main-nav li:nth-child(6) a {
        transition: all 275ms 550ms
    }
    .main-nav li:nth-child(7) a {
      transition: all 275ms 625ms
    }
    .main-nav li:nth-child(8) a {
        transition: all 275ms 700ms
    }
    .main-nav li:nth-child(9) a {
        transition: all 275ms 775ms
    }


    /* Decoration */
    .main-nav ul,
    .main-nav li {
        list-style: none;
        padding: 0;
    }
    .main-nav a {
        display: block;
        padding: 8px 0;
        color: $primary-color;
        font-size: 1.4em;
        text-decoration: none;
        font-weight: bold;
        &:hover{
          color: #999999;
        }
    }

      /* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
      .open-main-nav {
          position: relative;
          // top: 30px;
          padding-top: 18px;
          // right: 30px;
          z-index: 1000;
          background: none;
          border: 0;
          cursor: pointer;
      }
      .open-main-nav:focus {
          outline: none;
      }
      .burger {
          position: relative;
          display: block;
          width: 28px;
          height: 4px;
          margin: 0 auto;
          background: $fourth-color;
          transform: skew(5deg);
          transition: all .275s;
      }

      .burger:after,
      .burger:before {
          content: '';
          display: block;
          height: 100%;
          background: $secondary-color;
          transition: all .275s;
      }

      .burger:after {
          transform: translateY(-12px) translateX(-2px) skew(-20deg);
      }

      .burger:before {
          transform: translateY(-16px) skew(-10deg);
      }

      /* Toggle State part */
      .is-open .burger {
          transform: skew(5deg) translateY(-8px) rotate(-45deg);
      }

      .is-open .burger:before {
          transform: translateY(0px) skew(-10deg) rotate(75deg);
      }

      .is-open .burger:after {
          transform: translateY(-12px) translateX(10px) skew(-20deg);
          opacity: 0;
      }

      /* MENU Text part */

    .burger-text {
      display: block;
      font-size: .675rem;
      letter-spacing: .05em;
      margin-top: .5em;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      color: #343a40;
    }

  .card-img-camp-overlay{
    position: absolute;
    // background: linear-gradient(rgba(100,100,100,0.8) , rgba(100,100,100,0.8));
    background-color: #454545;
    opacity: 0.8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    // z-index: 10;
    transition: all 0.4s;
    border-radius: calc(0.25rem - 1px);
  }

  .card-img-camp-overlay .card-title {
    transition: all 0.4s;
  }

  .card-img-camp-overlay:hover{
    opacity: 0.5;
  }

  .card-img-camp-overlay:hover + div>h5.card-title{
    font-weight: 800;
  }

  .card-camp{
    border: none;
  }


      // .box-img {
      //   width: 200px;
      //   height: 200px;
      //   background: url("https://picsum.photos/200/300");
      //   background-color: black;
      //   background-size: cover;
      //   cursor: pointer;

      //   -webkit-box-shadow: 0 0 5px #000;
      //         box-shadow: 0 0 5px #000;
      //   }

      .overlay {

        height: 100%;
        width: 100%;
        // background: rgba(77, 77, 77, .9);
        background-color: #010101;
        color: $accent-color;
        opacity: 0;
        transition: all 0.5s;

        font-family: 'Playfair Display', serif;
      }

      .box-img:hover .overlay {
        opacity: 1;
        & .head {
          transform: translateY(0px);
        }
      }


      .items {
        padding-left: 20px;
        letter-spacing: 3px;
      }

      .head {
        font-size: 30px;
        line-height: 40px;

        transform: translateY(40px);
        transition: all 0.7s;
        hr {
          display: block;
          width: 0;

          border: none;
          border-bottom: solid 2px $accent-color;

          position: absolute;
          bottom: 0; left:20px;

          transition: all .5s;
        }
      }


//
.masthead {
    height: 70vh;
    width: 100%;
    background: linear-gradient(rgba(180,180,180, .5),rgba(180,180,180, .5)), url('/img/claudioPrimoPiano/claudio-76.jpg');
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.carousel-item {
    height: 100vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.margin-bottom-custom{
    margin-bottom: 150px;
}

.margin-custom{
    margin-top: 120px;
    margin-bottom: 100px;
}

.social-links{
    width: 40px;
    height: 40px;
    font-size: 18px;
    border: 2px solid $secondary-color;
    box-shadow: $secondary-color;

    backdrop-filter: blur(12px);
    border-radius: 20px;
}
.social-links-footer{
    width: 40px;
    height: 40px;
    font-size: 18px;
    border: 2px solid $primary-color;
    border-radius: 20px;
}

.work-card{
    position: relative;
    min-height: 400px;
}
.work-card:hover{
    background-color: linear-gradient(rgba(180,180,180, .5),rgba(180,180,180, .5)) !important;
    backdrop-filter: blur(12px);

    // background-color: $secondary-color !important;
}
.custom-card{
    position: absolute;
    top: 200px;
}

.card-img-custom{
    background: url('/storage/images/stocotto4.jpg');
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all .2s ease-in-out;
}
.card-img-custom:hover{
    background:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/storage/images/stocotto4.jpg');
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: scale(1.1);
}

//Card Category
.parent {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.child {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.bg-one{
    background-image: url('/img/categorie/food.jpg');
    background-size: cover;
}
.bg-two{
    background-image: url('/img/categorie/social.jpg');
    background-size: cover;
}
.bg-three{
    background-image: url('/img/categorie/corporate.jpg');
    background-size: cover;
}
.bg-four{
    background-image: url('/img/categorie/fashion.jpg');
    background-size: cover;
}
.bg-five{
    background-image: url('/img/categorie/medical.jpg');
    background-size: cover;
}
.bg-six{
    background-image: url('/img/categorie/personale.jpg');
    background-size: cover;
}
p.category-custom {
    display: none;
    font-size: 35px;
    color: #ffffff !important;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    cursor: pointer;
    /*text-decoration: none;*/
}

.parent:hover .child, .parent:focus .child {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.parent:hover .child:before, .parent:focus .child:before {
    display: block;
}

.parent:hover p, .parent:focus p {
    display: block;
}

.child:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(64, 64, 64, 0.75);
}

/* Media Queries */
@media screen and (max-width: 960px) {
    .parent {width: 100%; margin: 20px 0px}
    .wrapper {padding: 20px 20px;}
}

//end card category

hr{
    border-bottom:solid 2px $primary-color !important;
    width: 100px;
}
hr.title{
    border-bottom:solid 2px $secondary-color !important;
    width: 100px;
}

.btn-custom {
    background: none;
    border: 2px solid $secondary-color;
    color: $secondary-color;
    line-height: 1;
    // margin: 0.5em;
    padding: 20px;
    width: 140px !important;
    text-align: center !important;
    margin-bottom: 5px;
    margin-right: 3px;
    transition: 0.6s;
}
.btn-custom:hover, .btn-custom:focus {
    box-shadow: inset 0 -3.25em 0 0 $fourth-color;
    color: $primary-color ;
    transition: 0.6s;
}

.btn-custom-eye {
    background: none;
    border: 2px solid $secondary-color;
    color: $secondary-color;
    line-height: 1;
    // margin: 0.5em;
    width: 50px !important;
    text-align: center !important;
    margin-bottom: 5px;
    margin-right: 3px;
    transition: 0.6s;
}
.btn-custom-eye:hover, .btn-custom-eye:focus {
    box-shadow: inset 0 -3.25em 0 0 $fourth-color;
    color: wheat ;
    transition: 0.6s;
}

.btn-custom-red {
    background: none;
    border: 2px solid #BA0000;
    color: #BA0000;
    line-height: 1;
    // margin: 0.5em;
    padding: 15px;
    width: 150px !important;
    text-align: center !important;
    margin-bottom: 5px;
    margin-right: 3px;
    transition: 0.6s;
}

.btn-custom-red:hover, .btn-custom-red:focus {
    box-shadow: inset 0 -3.25em 0 0 #ba0000;
    color: wheat ;
    transition: 0.6s;
}

.btn-custom-green {
    background: none;
    border: 2px solid #81a140;
    color: #81a140;
    line-height: 1;
    // margin: 0.5em;
    padding: 15px;
    width: 150px !important;
    text-align: center !important;
    margin-bottom: 5px;
    margin-right: 3px;
    transition: 0.6s;
}

.btn-custom-green:hover, .btn-custom-green:focus {
    box-shadow: inset 0 -3.25em 0 0 #81a140;
    color: wheat ;
    transition: 0.6s;
}

.btn-custom-form {
    background: none;
    border: 2px solid $primary-color;
    color: $primary-color;
    line-height: 1;
    margin: 0.5em;
    padding: 1em 2em;
    transition: 0.6s;
}
.btn-custom-form:hover, .btn-custom-form:focus {
    box-shadow: inset 0 -3.25em 0 0 $fourth-color;
    color: $primary-color ;
    transition: 0.6s;
}
.btn-custom-masthead {
    background: none;
    border: 2px solid $secondary-color;
    // box-shadow: inset 0 -3.25em 0 0 $secondary-color;
    backdrop-filter: blur(12px);
    color: $primary-color;
    line-height: 1;
    width: 140px !important;
    margin: 0.5em;
    padding: 1em 2em;
    transition: 0.6s;
}
.btn-custom-masthead:hover, .btn-custom-masthead:focus {
    box-shadow: inset 0 -3.25em 0 0 $fourth-color;
    color: $primary-color ;
    transition: 0.6s;
}

.back-arrow{
    background: none;
    border: 2px solid $secondary-color;
    color: $secondary-color;
    backdrop-filter: blur(12px);
    transition: 0.6s;
}

input.border-form-login::placeholder{
    color: $secondary-color !important;
}
.border-form{
    border: $secondary-color;
    border-bottom: solid 2px $primary-color;
}
.border-form-login{
    border: $primary-color;
    border-bottom: solid 2px $secondary-color;
}

.form-control:focus {
    border-color: $fourth-color !important;
    box-shadow: none !important;
}
a.mast-color {
    color: $secondary-color;
    transition: 0.5s;
}
a.mast-color:hover{
    color: $primary-color;
    transition: 0.5s;
}
.mast-color2 {
    color: $fourth-color;
    transition: 0.5s;
}
.mast-color2:hover{
    color: $secondary-color;
    transition: 0.5s;
}
a.footer-color {
    color: $primary-color;
    transition: 0.5s;
}
a.footer-color:hover{
    color: $fourth-color;
    transition: 0.5s;
}

a.nav-link{
    color: $fourth-color !important;
}
a.login{
    color: $secondary-color !important;
}
a.login:hover{
    color: $fourth-color !important;
}


// .card-custom1{

//     background-size: cover;
//     background-position: center;
//     transition: 0.6s;
// }
.cardhw{
    height: 100%;
    width: 255px;
    transition: 1s;
    overflow: hidden;
}
.card-description{
    top: 0%;
    height: 100%;
    background-color: rgba(64, 64, 64, 0.4);
    transition: 1s;
    // cursor: pointer;
}

.cardhw:hover .card-description{
    top: 0%;
}

@media only screen and (max-width: 992px) {
    .card-description{
        top: 0%;
        height: 100%;
        background-color: rgba(64, 64, 64, 0.4);
        transition: 1s;
        // cursor: pointer;
    }
  }

@media only screen and (max-width: 576px){
    .margin-bottom-custom{
        margin-bottom: 1px !important;
    }
}

// .card-custom1:hover{
//     background:linear-gradient(rgba(131, 190, 85, 0.6), rgba(131, 190, 85, 0.6));
//     background-size: cover;
//     background-position: center;
//     transition: 0.6s;
// }
.cardBorder{

    border: solid 0px $accent-color;
    width: 100%;
}
.cardBorder2{

    border-top: solid 0px $accent-color;
    width: 100%;
}

.carousel-fade .carousel-item {
	opacity: 0 !important;
	transition-duration: .4s !important;
	transition-property: opacity !important;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1 !important;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0 !important;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	transform: translateX(0) !important;
	transform: translate3d(0, 0, 0) !important;
}

.masthead-404 {
    height: 100vh;
    min-height: 500px;
    background:linear-gradient(to left, rgba(200, 200, 200, 0.5), rgba(200,200,200, .5)), url('/img/404.gif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
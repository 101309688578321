// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~dropzone/dist/basic';
@import '~dropzone/dist/dropzone';


@import 'style';
